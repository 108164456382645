import React from 'react';
import Terms from "../../components/Terms";
import PageHead from "../../components/Helper/PageHead";

function Index() {
    return (
        <>
            <PageHead title="Whizz | Общи условия"/>
            <Terms/>
        </>

    );
}

export default Index;