import React, { useRef, useState } from 'react';
import useReCaptcha from '../../Hooks/useReCaptcha';

const { REACT_APP_API_URL } = process.env;

function Forms() {

    const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const form = useRef();
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        body: '',
        acceptTerms: '',
      });

    const validate = (formData) => {
        const errors = {}
        if (!formData.terms) {
            errors['acceptTerms'] = 'Трябва да се съгласите с общите условия';
        }

        const requiredFields = [
            'firstName',
            'lastName',
            'phone',
            'email',
            'body'
        ];
        
        
        for (let key of requiredFields) {
            if (formData[key].length === 0) {
                errors[key] = 'Задължително поле';
            }
        }

        if (Object.keys(errors).length > 0) {
            setErrors((prev) => ({
                ...prev,
                ...errors,
            }));
            return false;
        }

        return true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        const formData = {
          firstName: event.target.elements.firstName.value,
          lastName: event.target.elements.lastName.value,
          email: event.target.elements.email.value,
          phone: event.target.elements.phone.value,
          subject: 'Изтриване на акаунт',
          body: event.target.elements.body.value,
          terms: event.target.elements.terms.checked,
        };
        if (!validate(formData)) {
            setLoading(false);
            return;
        }
    
        try {
          const token = await generateReCaptchaToken('order');
          const submitData = { ...formData, token };
          const response  = await fetch(`${REACT_APP_API_URL}/email/contact`, {
            method: 'POST', 
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(submitData)
          });
          if (!response.ok) {
            throw new Error('Нещо се обърка. Моля опитайте отново!')
          }
          form.current.reset();
          setSuccess(true);
          setLoading(false);
        } catch (e) {
            setErrors((prev) => ({
                ...prev,
                ...{global: e.message},
            }));
            setLoading(false);  
        }
    };

    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Свържи се с нас</h3>
                                <p>Имаш нужда от помощ? Попълни формат и започни ново приключение.</p>
                                <div className="single-info">
                                    <h5>Еврика Софт ЕООД - Централен офис</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        Калоян 12, Карлово, 4300
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Телефон</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        (+359) 887 808 505
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Поддръжка</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        whizz@eurekasoft.eu
                                    </p>
                                </div>
                                {/* <div className="ab-social">
                                    <h5>Последвай ни</h5>
                                    <a className="fac" href="#">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="twi" href="#">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a className="you" href="#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                    <a className="lin" href="#">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Изтриване на акаунт</h4>
                                <p>Ако иксате вашият акаунт да бъде изтрит моля попъленете всички задължителни полета от формата и опишете защо искате вашите данни да бъдат изтрити.</p>
                                <p>Наш сътрудник ще се свържи с вас за потвърждение и след това всички ваши данни ще бъдат изтрити.</p>
                                
                                {success && 
                                    <div className='alert alert-success'>Вашето запитване беше изпратено успешно!</div>
                                }
                                {errors.global && 
                                    <div className='alert alert-danger'>{errors.global}</div>
                                }
                                <form action="#" ref={form} onSubmit={handleSubmit} method="post" className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="firstName" placeholder="Име" required className={errors.firstName.length > 0 ? 'is-invalid' : ''} />
                                        {errors.firstName.length > 0 && 
                                            <div className="invalid-feedback">{errors.firstName}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="lastName" placeholder="Фамилия" required  className={errors.lastName.length > 0 ? 'is-invalid' : ''} />
                                        {errors.lastName.length > 0 && 
                                            <div className="invalid-feedback">{errors.lastName}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Емайл"
                                            required
                                            className={errors.email.length > 0 ? 'is-invalid' : ''} 
                                        />
                                        {errors.email.length > 0 && 
                                            <div className="invalid-feedback">{errors.email}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Телефон"
                                            required
                                            className={errors.phone.length > 0 ? 'is-invalid' : ''} 
                                        />
                                        {errors.phone.length > 0 && 
                                            <div className="invalid-feedback">{errors.phone}</div>
                                        }
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="body"
                                            placeholder="От какво имате нужда?"
                                            required
                                            className={errors.body.length > 0 ? 'is-invalid' : ''} 
                                        ></textarea>
                                        {errors.body.length > 0 && 
                                            <div className="invalid-feedback">{errors.body}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="terms-conditions" name="terms" type="checkbox" className={errors.acceptTerms.length > 0 ? 'is-invalid' : ''}  />
                                            <label htmlFor="terms-conditions">
                                                Съгласявам се с <a href="#">общите условия</a>
                                            </label>
                                            {errors.acceptTerms.length > 0 && 
                                                <div className="invalid-feedback">{errors.acceptTerms}</div>
                                            }  
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" disabled={!reCaptchaLoaded || loading} value="Изпрати съобщението" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Forms;
