import React from 'react';
import { Link } from 'react-router-dom';

function Drawer({ drawer, action, lang }) {
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={`offcanvas_menu_wrapper ${
                                    drawer ? 'active' : ''
                                }`}
                            >
                                <div className="canvas_close">
                                    <Link to="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </Link>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src="/assets/images/logo-9.png" alt="Whizz" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li
                                            id="whizz"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/">Whizz</Link>
                                        </li>
                                        <li
                                            id="business"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/business">Whizz Business</Link>
                                        </li>
                                        <li
                                            id="about"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/about-us">За нас</Link>
                                        </li>
                                        <li
                                            id="contact"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/contact">Контакти</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <Link to="$">
                                                <i className="fab fa-facebook-f"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="$">
                                                <i className="fab fa-twitter"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="$">
                                                <i className="fab fa-instagram"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="$">
                                                <i className="fab fa-dribbble"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="mailto:whizz@eurekasoft.eu">
                                                <i className="fal fa-envelope"></i>{' '}
                                                whizz@eurekasoft.eu
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+359887808505">
                                                <i className="fal fa-phone"></i> +(359) 887 808 505
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-map-marker-alt"></i>{' '}
                                                Калоян 12, Карлово, 4300
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
