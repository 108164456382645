import React from 'react';
import Contact from "../../components/Contact";
import PageHead from "../../components/Helper/PageHead";

function Index() {
    return (
        <>
            <PageHead title="Whizz | Контакти"/>
            <Contact/>
        </>

    );
}

export default Index;