import React from 'react';
import PageHead from "../../components/Helper/PageHead";
import HomeFive from '../../components/HomeFive';

export default function Business() {
    return (
        <>
            <PageHead title="Whizz - Business"/>
            <HomeFive />
        </>

    );
}
