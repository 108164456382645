import React from 'react';
import { Link } from 'react-router-dom';


function FooterHomeEight() {
    return (
        <>
            <section className="appie-footer-area appie-footer-about-area appie-footer-8-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src='/assets/images/logo-5.png' alt="" />
                                    </a>
                                </div>
                                <p>
                                    Whizz for Business
                                </p>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Whizz</h4>
                                <ul>
                                    <li>
                                        <Link to="/">Whizz</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">Whizz Business</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">За нас</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Контакти</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Политики и въпроси</h4>
                                <ul>
                                    <li>
                                        <Link to="/privacy">Декларация за поверителност</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms">Общи условия</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Свържи се с нас</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:whizz@eurekasoft.eu">
                                            <i className="fal fa-envelope"></i> whizz@eurekasoft.eu
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+359887808505">
                                            <i className="fal fa-phone"></i> +(359) 887 808 505
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt"></i> България, Карлово, ул. Калоян 12
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
                            >
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="https://apps.apple.com/app/whizz-business/id6575384231" target='_blank' rel="noreferrer">
                                                <i className="fab fa-apple"></i> Свали за iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="https://play.google.com/store/apps/details?id=eu.eurekasoft.whizz_business" target='_blank' rel="noreferrer">
                                                <i className="fab fa-google-play"></i> Свали за
                                                Android
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2024 Whizz. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeEight;
