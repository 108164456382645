import React from 'react';

function DetailsService() {
    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="content">
                                    <h3 className="title">Въведение</h3>
                                    <p>Това е Декларация за поверителност ("Декларация") на мобилното приложение за резервации "Whizz" ("Приложението"). Приложението се предоставя от "Еврика Софт ЕООД" ("Компанията", "ние" или "нас").</p>
                                    <p>Тази Декларация описва как събираме, използваме и разкриваме Вашата лична информация, когато използвате Приложението. <br /> 
                                        Използвайки Приложението, Вие приемате условията на тази Декларация. <br />
                                        Ако не сте съгласни с условията на тази Декларация, моля не използвайте Приложението.</p>
                                    <h3 className="title">Събирана информация</h3>
                                    <p>Събираме различни видове информация, когато използвате Приложението, включително:</p>
                                    <p>Лична информация: Това включва информация, която Ви идентифицира като личност, като например Вашето име, имейл адрес, телефонен номер, адрес и платежна информация.<br />
                                        Демографска информация: Това може да включва информация за Вашата възраст, пол, местоположение и интереси.<br />
                                        Информация за устройството: Това може да включва информация за Вашия телефон или друго устройство, което използвате за достъп до Приложението, като например Вашия IP адрес, тип устройство и операционна система.<br />
                                        Информация за използването: Това може да включва информация за това как използвате Приложението, като например кои услуги резервирате, кога и къде ги резервирате и с кого взаимодействате чрез Приложението.</p>
                                    <h3 className="title">Използване на информацията</h3>
                                    <p>Използваме Вашата информация за следните цели:</p>
                                    <p>За да Ви предоставим услугите, които предлагаме чрез Приложението.<br />
                                        За да подобрим Вашето изживяване при използване на Приложението.<br />
                                        За да Ви изпращаме маркетингови съобщения и оферти.<br />
                                        За да се свържем с Вас относно Вашите резервации или други въпроси, които може да имате.<br />
                                        За да защитим нашите права и имущество.</p>
                                    <h3 className="title">Разкриване на информация</h3>
                                    <p>Можем да разкриваме Вашата информация на следните лица:</p>
                                    <p>Доставчици на услуги, които ни помагат да Ви предоставим услугите, които предлагаме чрез Приложението, като например обработка на плащания, изпращане на имейл съобщения и предоставяне на обслужване на клиенти.<br />
                                        Трети страни, с които избирате да споделите информацията си.<br />
                                        Правоприлагащи органи или други държавни органи, когато това се изисква от закона.</p>
                                    <h3 className="title">Вашите избори</h3>
                                    <p>Можете да контролирате как Вашата информация се използва по следните начини:</p>
                                    <p>Можете да откажете да получавате маркетингови съобщения от нас. Можете да направите това, като щракнете върху връзката "Отписване" в долната част на всеки маркетингов имейл, който Ви изпращаме, или като се свържете с нас на whizz@eurekasoft.eu.<br />
                                        Можете да поискате достъп до Вашата лична информация или да я изтриете. Можете да направите това, като се свържете с нас на whizz@eurekasoft.eu.</p>
                                    <h3 className="title">Сигурност на данните</h3>
                                    <p>Предприемаме разумни мерки за защита на Вашата лична информация от неоторизиран достъп, използване, разкриване, модифициране или унищожаване. Въпреки това, моля, имайте предвид, че никой метод за предаване на информация през интернет или метод за съхранение на информация в цифрова форма не е 100% сигурен.</p>
                                    <h3 className="title">Промени в тази Декларация</h3>
                                    <p>Можем да актуализираме тази Декларация от време на време. Ще Ви уведомим за всякакви съществени промени, като публикуваме актуализираната Декларация в Приложението и/или като Ви изпратим имейл.</p>
                                    <h3 className="title">Свържете се с нас</h3>
                                    <p>Ако имате въпроси относно тази Декларация или Вашата лична информация, моля свържете се с нас на whizz@eurekasoft.eu.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
