import React from 'react';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                <h2 className="title">Whizz - book a slot</h2>
                                <p>We offer you a panoply of cutting-edge options.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-content">
                                <h3 className="title">
                                    Кои сме ние?
                                </h3>
                                <p>
                                    Whizz се разработва от Еврика Софт ЕООД. Екип от млади и амбициозни професионалисти, които вярват, че най-добрата рецепта за упех е работата с партньори.
                                </p>
                                <a href="https://eurekasoft.eu" target='_blank' rel="noreferrer" >
                                    Прочети повече за нас <i className="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
