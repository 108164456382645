import React from "react";
import { ScrollRestoration } from 'react-router-dom';
import {Helmet} from "react-helmet";

function PageHead(props) {
    const { title } = props;
    const { metaDes } = props;
    return (
        <>
            <Helmet>
                <title>{title || 'Whizz'}</title>
                <meta name="description" content={metaDes||`Whizz – мобилно приложение за резервиране на услуги`} />
                {/*<link*/}
                {/*    rel="icon"*/}
                {/*    href={`${*/}
                {/*        favicon ? process.env.NEXT_PUBLIC_BASE_URL + favicon : "/favico.svg"*/}
                {/*    }`}*/}
                {/*/>*/}
            </Helmet>
            <ScrollRestoration />
        </>
    );
}

export default PageHead;