import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import HeaderHomeEight from '../HomeEight/HeaderHomeEight';
import HeroNews from '../AboutUs/HeroNews';
import Forms from './Forms';
import FooterHomeEight from '../HomeEight/FooterHomeEight';

function DeleteAccount() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeEight drawer={drawer} action={drawerAction.toggle} />
            <HeroNews
                title="Изтриване на акаунт"
                breadcrumb={[
                    { link: '/', title: 'Начало' },
                    { link: '/delete-account', title: 'Изтриване на акаунт' },
                ]}
            />
            <Forms />
            <FooterHomeEight />
            <BackToTop />
        </>
    );
}

export default DeleteAccount;
