import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import DetailsService from './DetailsService';
import HeroService from './HeroService';
import FooterHomeEight from '../HomeEight/FooterHomeEight';
import HeaderHomeEight from '../HomeEight/HeaderHomeEight';

function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeEight action={drawerAction.toggle} />
            <HeroService />
            <DetailsService />
            <FooterHomeEight />
            <BackToTop />
        </>
    );
}

export default Service;
