import React from 'react';
import Privacy from "../../components/Privacy";
import PageHead from "../../components/Helper/PageHead";

function Index() {
    return (
        <>
            <PageHead title="Whizz | Декларация за поверителност"/>
            <Privacy/>
        </>

    );
}

export default Index;