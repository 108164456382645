import React from 'react';


function DetailsService() {
    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="content">
                                    <h3 className="title">Въведение</h3>
                                    <p>
                                        Тези Общи условия ("Условия") уреждат Вашето ползване на мобилното приложение за резервации "Whizz" ("Приложението"). Приложението се предоставя от "Еврика Софт ЕООД" ("Компанията", "ние" или "нас").
                                    </p>
                                    <p>
                                        Използвайки Приложението, Вие приемате условията на тези Условия. Ако не сте съгласни с условията на тези Условия, моля не използвайте Приложението.
                                    </p>
                                    <h3 className="title">Дефиниции</h3>
                                    <ul>
                                        <li>"Учетен запис": Вашият акаунт в Приложението, който Ви позволява да резервирате услуги и да управлявате Вашата информация.</li>
                                        <li>"Услуги": Услугите, които се предлагат за резервация чрез Приложението, като например годишен технически преглед, фризьор, маникюр и други.</li>
                                        <li>"Потребител": Лице, което използва Приложението, за да резервира услуги.</li>
                                    </ul>  
                                    <h3 className="title">
                                        Регистрация на акаунт
                                    </h3>
                                    <p>
                                        За да използвате някои от функциите на Приложението, ще трябва да създадете акаунт. При създаването на акаунт ще трябва да предоставите определена лична информация, като например Вашето име, имейл адрес и телефонен номер. Вие сте отговорни за поддържането на точността и актуалността на Вашата информация за акаунта.
                                    </p>
                                    <h3 className="title">Резервации на услуги</h3>
                                    <p>
                                        Можете да резервирате услуги чрез Приложението, като потърсите доставчик на услуги, изберете желаната услуга и дата/час и въведете Вашата платежна информация. След като резервирате услуга, ще получите потвърждение по имейл.
                                    </p>
                                    <h3 className="title">Отмяна и промяна на резервации</h3>
                                    <p>
                                        Можете да отмените или промените резервациите си чрез Приложението до определен момент преди уговорената дата и час на услугата. Моля, проверете политиката за отмяна на отделния доставчик на услуги за повече информация.
                                    </p>
                                    <h3 className="title">Цени и плащания</h3>
                                    <p>
                                        Цените на услугите се определят от отделните доставчици на услуги. Ще бъдете таксувани на место от доставчика на услугата.
                                    </p>
                                    <h3 className="title">Правила за ползване</h3>
                                    <p>
                                        Вие се съгласявате да използвате Приложението само за законни цели и в съответствие с тези Условия. Вие се съгласявате да не използвате Приложението за каквито и да било незаконни или неоторизирани цели, включително, но не само за:
                                    </p>
                                    <ul>
                                        <li>Нарушаване на правата на интелектуална собственост на други лица.</li>
                                        <li>Изпращане на спам или други нежелани съобщения.</li>
                                        <li>Опит за хакване или нарушаване на сигурността на Приложението.</li>
                                        <li>Разпространение на вируси или друг злонамерен софтуер.</li>
                                        <li>Нарушаване на поверителността или правата на други лица.</li>
                                    </ul>
                                    <h3 className="title">Ограничаване на отговорността</h3>
                                    <p>Компанията не носи отговорност за каквито и да било преки, косвени, случайни, специални, последващи или наказателни щети, произтичащи от или по някакъв начин свързани с Вашето използване на Приложението, дори ако Компанията е била уведомена за възможността за такива щети.</p>
                                    <h3 className="title">Прекратяване</h3>
                                    <p>Компанията може да прекрати Вашия акаунт и достъпа Ви до Приложението по всяко време, без предизвестие, по каквато и да е причина.</p>
                                    <h3 className="title">Промени в Условията</h3>
                                    <p>Компанията може да променя тези Условия по всяко време. Ще Ви уведомим за всякакви съществени промени, като публикуваме актуализираните Условия в Приложението и/или като Ви изпратим имейл.</p>
                                    <h3 className="title">Общи разпоредби</h3>
                                    <p>Тези Условия се уреждат и тълкуват в съответствие със законите на България. Всякакви спорове, произтичащи от или свързани с тези Условия, ще бъдат уредени изключително от компетентните съдилища на България</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
