import React from 'react';

function HeroHomeFive() {
    return (
        <>
            <section className="appie-hero-area appie-hero-4-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="appie-hero-content appie-hero-content-4">
                                <span>Напълно безплатно</span>
                                <h1 className="appie-title">Вкарай графика си в ред</h1>
                                <p>
                                    Whizz бизнес е уникално приложение което свързва бизнеса с крайните клиенти само с няколко клика. 
                                    Забрави за хартиените тефтери и носи графика си винаги с теб. Организирай много работни места от едно място.
                                </p>
                                <a className="main-btn" href="#">
                                    Нека поговорим
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="appie-hero-thumb appie-hero-thumb-4">
                                <img src='/assets/images/hero-thumb-5.png' alt="" />
                                <div className="hero-dot">
                                    <img src='/assets/images/hero-dot.png' alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src='/assets/images/shape/shape-10.png' alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeFive;
