import React from 'react';
import HomeEight from "../components/HomeEight";
import PageHead from "../components/Helper/PageHead";

export default function Whizz() {
    return (
        <>
            <PageHead/>
            <HomeEight/>
        </>

    );
}