// import React, { useState } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import TestimonialHomeEight from '../HomeEight/TestimonialHomeEight';
import SignupHomeEight from '../HomeEight/SignupHomeEight';
import DownloadHomeThree from './DownloadHomeThree';
import ServicesHomeThree from './ServicesHomeThree';
import FeaturesHomeTwo from './FeaturesHomeTwo';
import SponserHomeTwo from './SponserHomeTwo';
import Drawer from '../Mobile/Drawer';
import FooterHomeFive from './FooterHomeFive';
import HeaderHomeFive from './HeaderHomeFive';
import HeroHomeFive from './HeroHomeFive';
import ShowCaseHomeThree from './ShowCaseHomeThree';

function HomeFive() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeFive action={drawerAction.toggle} />
            <HeroHomeFive />
            <ServicesHomeThree />
            <FeaturesHomeTwo />
            <ShowCaseHomeThree />
            <DownloadHomeThree className="pb-90" />
            <TestimonialHomeEight />
            <SponserHomeTwo className="pt-90" />
            <SignupHomeEight className="appie-signup-8-area" />
            <FooterHomeFive />
            <BackToTop className="back-to-top-5" />
        </>
    );
}

export default HomeFive;
