import React from 'react';

function ServicesHomeThree() {
    return (
        <>
            <section className="appie-service-area appie-service-3-area pt-195 pb-100" id="service">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Какво можеш </h3>
                                <p>
                                    The full monty spiffing good time no biggie cack grub fantastic.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src= '/assets/images/icon/1.png'alt="" />
                                </div>
                                <h4 className="appie-title">Лесно за използване</h4>
                                <p>Менажираш работни места, услуги и резервации бързо и лесно.</p>
                                <a href="#">
                                    Прочети повече <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <img src='/assets/images/icon/2.png' alt="" />
                                </div>
                                <h4 className="appie-title">Без недоволни клиенти</h4>
                                <p>Спри досадните обаждания и дублираните резервации</p>
                                <a href="#">
                                    Прочети повече <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src='/assets/images/icon/3.png' alt="" />
                                </div>
                                <h4 className="appie-title">Напълно сигурно</h4>
                                <p>Твоите данни са достъпни само за теб.</p>
                                <a href="#">
                                    Прочети повече <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <div className="icon">
                                    <img src='/assets/images/icon/4.png' alt="" />
                                </div>
                                <h4 className="appie-title">Подкрепа от наша страна</h4>
                                <p>Безплатно обучение и помощ при нужда.</p>
                                <a href="#">
                                    Прочети повече <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeThree;
