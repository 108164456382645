import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css';
import './assets/css/bootstrap.min.css';
import './assets/css/custom-animated.css';
import './assets/css/default.css';
import './assets/css/font-awesome.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/magnific.dark.css';
import './assets/css/magnific.rtl.css';
import './assets/css/main.css';
import './assets/css/style.css';
import reportWebVitals from './reportWebVitals';
import Whizz from './rootes/whizz';
import Business from './rootes/business';
import AboutUs from './rootes/about-us';
import Terms from './rootes/terms';
import Privacy from './rootes/privacy';
import Contact from './rootes/contact';
import DeleteAccount from './rootes/delete-account';
import Error404 from './rootes/404';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Whizz />,
  },
  {
    path: "/business",
    element: <Business />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/delete-account",
    element: <DeleteAccount />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
]);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
      <RouterProvider router={router} />
  );
} else {
  const root = createRoot(rootElement);

  root.render(
      <RouterProvider 
        router={router} 
      />,
  );
}

reportWebVitals();
