import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import SignupHomeEight from '../HomeEight/SignupHomeEight';
import TestimonialHomeEight from '../HomeEight/TestimonialHomeEight';
import ServicesHomeThree from '../HomeFive/ServicesHomeThree';
import Drawer from '../Mobile/Drawer';
import HeaderHomeEight from '../HomeEight/HeaderHomeEight';
import HeroNews from './HeroNews';
import FooterHomeEight from '../HomeEight/FooterHomeEight';
import HeroAbout from './HeroAbout';

function AboutUsTwo() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeEight action={drawerAction.toggle} />
            <HeroNews
                title="За нас"
                breadcrumb={[
                    { link: '/', title: 'Начало' },
                    { link: '/about-us', title: 'За нас' },
                ]}
            />
            <HeroAbout />
            <ServicesHomeThree style={{ paddingTop: '90px' }} />
            <TestimonialHomeEight />
            <SignupHomeEight />
            <FooterHomeEight className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default AboutUsTwo;
