import React from 'react';

function AboutHomeEight() {
    return (
        <>
            <section className="appie-about-8-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title mb-30">
                                <h3 className="appie-title">
                                    Ние свързваме бизнеса и крайният клиент <br />
                                    просто с няколко клика от твоят телефон
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-8-box">
                                <h3 className="title">
                                    Мобилно потребителско <br />
                                    приложение 
                                </h3>
                                <p>
                                    Намери най-подходящите за теб услуги <br />
                                    и си запази час за секунди
                                </p>
                                <a className="main-btn" href="#">
                                    Прочети повече <i className="fal fa-arrow-right" />
                                </a>
                                <div className="thumb">
                                    <img src='/assets/images/about-thumb-4.png' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="appie-about-8-box">
                                <h3 className="title">
                                    Website, Mobile & Desctop <br />
                                    бизнес приложение
                                </h3>
                                <p>
                                    Бъди винаги в крак със своя график <br />
                                    без значение от използваната платформа
                                </p>
                                <a className="main-btn" href="#">
                                    Прочети повече <i className="fal fa-arrow-right" />
                                </a>
                                <div className="thumb mr-30">
                                    <img src='/assets/images/about-thumb-5.png' alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutHomeEight;
