import React from 'react';
import AboutUs from "../../components/AboutUs";
import PageHead from "../../components/Helper/PageHead";

function Index() {
    return (
        <>
            <PageHead title="Whizz | За нас"/>
            <AboutUs/>
        </>

    );
}

export default Index;