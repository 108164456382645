import React from 'react';
import DeleteAccount from "../../components/DeleteAccount";
import PageHead from "../../components/Helper/PageHead";

function Index() {
    return (
        <>
            <PageHead title="Whizz | Изтриване на акаунт"/>
            <DeleteAccount/>
        </>

    );
}

export default Index;