import React, { useRef, useState } from 'react';
import useReCaptcha from '../../Hooks/useReCaptcha';

const { REACT_APP_API_URL } = process.env;

function SponserHomeEight({ className }) {

    const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const form = useRef();
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
      });

    const validate = (formData) => {
        const errors = {}

        const requiredFields = [
            'name',
            'phone',
            'email'
        ];
        
        
        for (let key of requiredFields) {
            if (formData[key].length === 0) {
                errors[key] = 'Задължително поле';
            }
        }

        if (Object.keys(errors).length > 0) {
            setErrors((prev) => ({
                ...prev,
                ...errors,
            }));
            return false;
        }

        return true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        const formData = {
          name: event.target.elements.name.value,
          email: event.target.elements.email.value,
          phone: event.target.elements.phone.value,
        };
        if (!validate(formData)) {
            setLoading(false);
            return;
        }
    
        try {
          const token = await generateReCaptchaToken('order');
          const submitData = { ...formData, token };
          const response  = await fetch(`${REACT_APP_API_URL}/email/register`, {
            method: 'POST', 
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(submitData)
          });
          if (!response.ok) {
            throw new Error('Нещо се обърка. Моля опитайте отново!')
          }
          
          setSuccess(true);
          form.current.reset();
          setLoading(false);
        } catch (e) {
            setErrors((prev) => ({
                ...prev,
                ...{global: e.message},
            }));
            setLoading(false);  
        }
    };

    return (
        <>
            <div className={`appie-signup-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-signup-box">
                                <span>Нямаш бизнес акаунт</span>
                                <h3 className="title">Направи си сега.</h3>
                                {success && 
                                    <div className='alert alert-success'>Вашето запитване за регистраиця беше изпратено успешно!</div>
                                }
                                {errors.global && 
                                    <div className='alert alert-danger'>{errors.global}</div>
                                }
                                <form action="#" ref={form} onSubmit={handleSubmit} method="post">
                                    <div className="input-box">
                                        <input type="text" name="name" placeholder="Вашето име" className={errors.name.length > 0 ? 'is-invalid' : ''} required/>
                                        {errors.name.length > 0 && 
                                            <div className="invalid-feedback">{errors.name}</div>
                                        }
                                    </div>
                                    <div className="input-box">
                                        <input type="email" name="email" placeholder="Вашият емайл" className={errors.email.length > 0 ? 'is-invalid' : ''} required/>
                                        {errors.email.length > 0 && 
                                            <div className="invalid-feedback">{errors.email}</div>
                                        }
                                    </div>
                                    <div className="input-box">
                                        <input type="phone" name="phone" placeholder="Вашият телефон" className={errors.phone.length > 0 ? 'is-invalid' : ''} required/>
                                        {errors.phone.length > 0 && 
                                            <div className="invalid-feedback">{errors.phone}</div>
                                        }
                                    </div>
                                    <div className="input-box">
                                        <button type="submit" disabled={!reCaptchaLoaded || loading}>Свържи се с нас</button>
                                    </div>
                                </form>
                                <div className="thumb">
                                    <img src='/assets/images/signup-thumb.png' alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SponserHomeEight;
